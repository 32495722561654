import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiEndpoints } from '@core/constants';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  selectedRegion = localStorage.getItem('region') || 'US';
  constructor(private cookieService: CookieService, private http: HttpClient) {}
  selectedProject = '';
  env = environment.config.client;

  ngOnInit(): void {
    this.getProjectName();
  }

  private getProjectName() {
    let projectId = this.cookieService.get('project_id');
    const url =
      environment.config.pacHomeUrl +
      '/' +
      ApiEndpoints.PROJECT_DETAILS +
      '/' +
      projectId;
    this.http.get(url).subscribe((data: any) => {
      this.selectedProject = data['data'].project_name;
    });
  }
}
