import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  API_SUCCESS,
  ApiEndpoints,
  CONCEPT_APPROVED_SUCCESS,
  CONCEPT_NO_DATA,
  CONCEPT_REJECTED_SUCCESS,
  SOMETHING_WENT_WRONG,
} from '@core/constants';
import { AdminModalConfig } from '@core/models';
import {
  CODES_TABLE_COLUMNS,
  CONCEPT_REQUEST_COLUMNS,
  ModalButtonConfig,
} from '@core/models/admin.model';
import {
  AuthService,
  TransactionManagerService,
  UserMappingService,
} from '@core/services';
import { Utils } from '@shared/Utils';
import { RDSelectOptions } from '@zs-ca/angular-cd-library';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-approve-requests',
  templateUrl: './approve-requests.component.html',
  styleUrls: ['./approve-requests.component.scss'],
})
export class ApproveRequestsComponent implements OnInit, OnDestroy {
  public pageParams = {
    searchText: '',
    isSearchApplied: false,
    searchColumn: 'conceptName',
    showTaggingPopup: false,
  };

  isConceptLoaded: boolean = false;

  conceptLoadErrorMessage: string = '';

  searchSelectOptions: RDSelectOptions[] = [];

  private userId: string = '';

  public codesAccordion: any[] = [];

  public conceptStatus: any = {
    conceptSetId: '',
    userId: '',
    rejectComments: '',
    publishFlag: false,
    previousPublishFlag: '',
    globalFilterFlag: '',
    previousGlobalFilterFlag: '',
    errorMessage: '',
    errorType: '',
  };

  public codeDetails = {
    conceptSetName: '',
    conceptSetDesc: '',
    totalNumOfCodes: '',
    codes: {
      drug: {
        noOfCodes: null,
        data: [],
      },
      condition: {
        noOfCodes: null,
        data: [],
      },
      procedure: {
        noOfCodes: null,
        data: [],
      },
      measurement: {
        noOfCodes: null,
        data: [],
      },
      provider: {
        noOfCodes: null,
        data: [],
      },
    },
  };

  private reviewModalButtons = [
    {
      btnText: 'Cancel',
      btnType: 'default',
      btnStyle: {},
      handler: () => {
        this.modalConfig.isVisible = false;
      },
      isDisabled: () => false,
    },
    {
      btnText: 'Reject',
      btnType: 'default',
      btnStyle: {
        'margin-left': '1rem',
        border: '1px solid #B21111',
        color: '#B21111',
      },
      handler: () => {
        this.openModal(
          'rejectConcept',
          `Reject Concept`,
          '545px',
          this.rejectModalButtons
        );
      },
      isDisabled: () => !this.isConceptLoaded,
    },
    {
      btnText: 'Approve',
      btnType: 'primary',
      btnStyle: { 'margin-left': '1rem' },
      handler: () => {
        this.openModal(
          'approveConcept',
          `Approve Concept`,
          '545px',
          this.approveModalButtons
        );
      },
      isDisabled: () => !this.isConceptLoaded,
    },
  ];

  private rejectModalButtons = [
    {
      btnText: 'Cancel',
      btnType: 'default',
      btnStyle: {},
      handler: () => {
        this.modalConfig.isVisible = false;
      },
      isDisabled: () => false,
    },
    {
      btnText: 'Reject Concept',
      btnType: 'primary',
      btnStyle: { 'margin-left': '1rem' },
      handler: () => {
        this.modalConfig.isVisible = false;
        this.conceptStatus.publishFlag = false;
        this.conceptStatus.globalFilterFlag = false;
        this.updateConceptStatus();
      },
      isDisabled: () => false,
    },
  ];

  private approveModalButtons = [
    {
      btnText: 'Cancel',
      btnType: 'default',
      btnStyle: {},
      handler: () => {
        this.modalConfig.isVisible = false;
      },
      isDisabled: () => false,
    },
    {
      btnText: 'Approve Concept',
      btnType: 'primary',
      btnStyle: { 'margin-left': '1rem' },
      handler: () => {
        this.modalConfig.isVisible = false;
        this.conceptStatus.publishFlag = true;
        this.conceptStatus.globalFilterFlag = true;
        this.updateConceptStatus();
      },
      isDisabled: () => false,
    },
  ];

  searchButtonStyle = {
    height: '30px',
    border: 'none',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    padding: '4px 4px 1px 10px',
  };

  modalConfig: AdminModalConfig = {
    modalName: '',
    isVisible: false,
    modalTitle: '',
    modalWidth: '',
    footerButton: [],
  };

  conceptRequestGrid = {
    tableData: [],
    tableColumns: CONCEPT_REQUEST_COLUMNS(),
    tableScrollDetails: { y: '320px' },
    pageIndex: 1,
    totalRequestsCount: 0,
    sortKey: 'submittedOn',
    sortType: 'desc',
    errorMessage: '',
    status: 'loading',
    limit: 10,
    offset: 0,
  };

  clickedConceptData: any = {};

  public listSubscription: Subscription = new Subscription();

  constructor(
    private dataService: TransactionManagerService,
    private authService: AuthService,
    private userMappingService: UserMappingService
  ) {}

  ngOnInit() {
    this.setSearchColumnOptions(CONCEPT_REQUEST_COLUMNS());
    this.loadConceptRequest();
    this.authService.getUserDetails().subscribe((userDetail) => {
      this.userId = userDetail.username || '';
    });
  }

  private setSearchColumnOptions(tableColumns: any): void {
    this.searchSelectOptions = [];
    tableColumns.filter((column: any) =>
      column.key === 'conceptName' ||
      column.key === 'domain' ||
      column.key === 'submittedBy'
        ? (this.searchSelectOptions = [
            ...this.searchSelectOptions,
            { label: column.name, value: column.key },
          ])
        : ''
    );
  }

  onSearch() {
    this.conceptRequestGrid.pageIndex = 1;
    this.conceptRequestGrid.offset = 0;
    this.loadConceptRequest();
  }

  resetSearch(searchText: string, type: string) {
    if (!searchText.trim()) {
      if (type === 'code') {
        this.pageParams.isSearchApplied = false;
        this.loadConceptRequest();
      } else {
        this.pageParams.isSearchApplied = false;
        this.loadCodeDetails(this.clickedConceptData.data.conceptSetId);
      }
    }
  }

  loadConceptRequest() {
    this.conceptRequestGrid.status = 'loading';
    const payload = {
      sortKey: this.conceptRequestGrid.sortKey,
      sortType: this.conceptRequestGrid.sortType,
      limit: this.conceptRequestGrid.limit,
      offset: this.conceptRequestGrid.offset,
      searchColumn: this.pageParams.searchColumn,
      searchText: this.pageParams.searchText,
    };
    this.listSubscription?.unsubscribe();
    this.listSubscription = this.dataService
      .post(ApiEndpoints.CONCEPT_REQUEST, payload)
      .subscribe({
        next: (response) => {
          if (response?.status === API_SUCCESS) {
            this.conceptRequestGrid.tableData = response?.data;
            this.conceptRequestGrid.totalRequestsCount = response.totalConcepts;
            if (this.conceptRequestGrid.tableData.length === 0) {
              this.conceptRequestGrid.errorMessage = CONCEPT_NO_DATA;
              this.conceptRequestGrid.status = 'unavailable';
            } else {
              this.transformGridData();
              this.conceptRequestGrid.status = 'available';
            }
          } else {
            this.conceptRequestGrid.status = 'unavailable';
            this.conceptRequestGrid.errorMessage = SOMETHING_WENT_WRONG;
          }
        },
        error: () => {
          this.conceptRequestGrid.status = 'unavailable';
          this.conceptRequestGrid.errorMessage = SOMETHING_WENT_WRONG;
        },
      });
  }

  loadCodeDetails(conceptId: string) {
    const payload = {
      searchText: this.pageParams.searchText,
      conceptSetId: conceptId,
    };
    this.isConceptLoaded = false;
    this.conceptLoadErrorMessage = '';
    this.dataService.post(ApiEndpoints.CODE_DETAILS, payload).subscribe({
      next: (response: any) => {
        if (response?.status === API_SUCCESS) {
          this.codeDetails = response;
          this.codesAccordion = [];
          Object.entries(this.codeDetails.codes).map((category: any) => {
            const panelHeader =
              '<div class="d-flex justify-content-between w-100"><div class="d-flex justify-content-between fw-bold fs-16">' +
              `${Utils.titleCase(category[0])}` +
              '</div><div class="fw-bold fs-16">' +
              category[1].noOfCodes +
              ' Codes</div></div>';
            this.codesAccordion.push({
              key: category[0],
              codeCount: category[1].noOfCodes,
              tableData: category[1].data.sort((a: any, b: any) =>
                a.vocabulary.localeCompare(b.vocabulary)
              ),
              tableColumn: CODES_TABLE_COLUMNS(),
              panelHeader: panelHeader,
              showArrowIcon: true,
            });
          });
          // re-order codes data
          this.codesAccordion.sort((a, b) => a.key.localeCompare(b.key));
          this.isConceptLoaded = true;
        } else {
          this.isConceptLoaded = false;
          this.conceptLoadErrorMessage = SOMETHING_WENT_WRONG;
        }
      },
      error: () => {
        this.isConceptLoaded = false;
        this.conceptLoadErrorMessage = SOMETHING_WENT_WRONG;
      },
    });
  }

  updateConceptStatus(): void {
    const payload = {
      conceptSetId: this.conceptStatus.conceptSetId,
      userId: this.conceptStatus.userId,
      rejectComments: this.conceptStatus.rejectComments || '',
      publishFlag: this.conceptStatus.publishFlag,
      previousPublishFlag: this.conceptStatus.previousPublishFlag,
      globalFilterFlag: this.conceptStatus.globalFilterFlag,
      previousGlobalFilterFlag: this.conceptStatus.previousGlobalFilterFlag,
    };
    this.conceptStatus.errorMessage = '';
    this.dataService
      .post(ApiEndpoints.APPROVE_REJECT_CONCEPT, payload)
      .subscribe({
        next: (response: any) => {
          if (response?.status === API_SUCCESS) {
            this.conceptStatus.errorType = response.isRequestDone
              ? 'success'
              : 'error';
            this.conceptStatus.errorMessage = response.isRequestDone
              ? this.conceptStatus.publishFlag
                ? CONCEPT_APPROVED_SUCCESS
                : CONCEPT_REJECTED_SUCCESS
              : SOMETHING_WENT_WRONG;
            setTimeout(() => {
              this.conceptStatus.errorMessage = '';
            }, 2000);
            this.loadConceptRequest();
          } else {
            this.conceptStatus.errorMessage = SOMETHING_WENT_WRONG;
          }
        },
        error: () => {
          this.conceptStatus.errorMessage = SOMETHING_WENT_WRONG;
        },
      });
  }

  transformGridData() {
    this.conceptRequestGrid.tableData.map((data: any) => {
      data.review = 'Review';
      data.review_iconBefore = [
        {
          type: 'custom:preview',
          style: {
            'font-size': '20px',
          },
        },
      ];
      if (data.submittedOn) {
        data.submittedOn_iconAfter = [
          {
            type: 'info-circle',
            style: { fontSize: '15px' },
            title: '',
            tooltip: `${Utils.getTime(data.submittedOn)} GMT`,
          },
        ];
      }
      Utils.formatGridData(data);
      data.isImportant = data.isImportant === '1' ? 'Yes' : 'No';
      data.isGlobalFilter = data.isGlobalFilter === '1' ? 'Yes' : 'No';
      data.submittedBy =
        this.userMappingService.userMapping?.[data.submittedBy] ||
        data.submittedBy;
    });
  }

  onTableRowClick(event: any) {
    this.clickedConceptData = event;
    if (event.column.key === 'conceptName') {
      this.loadCodeDetails(event.data.conceptSetId);
      this.openModal('reviewConcept', `${event.data.conceptName}`, '850px');
      this.modalConfig.isVisible = true;
    }
  }

  onLinkedColumnClick(event: any) {
    this.clickedConceptData = event;
    if (event.columnKey === 'review') {
      this.loadCodeDetails(event.columnData.conceptSetId);
      this.openModal(
        'reviewConcept',
        `Review Concept - ${event.columnData.conceptName}`,
        '850px',
        this.reviewModalButtons
      );
      this.modalConfig.isVisible = true;
      const { conceptSetId, publishedFlag, isGlobalFilter } = event.columnData;
      this.conceptStatus = {
        conceptSetId,
        userId: this.userId,
        previousPublishFlag: publishedFlag == 1,
        previousGlobalFilterFlag: isGlobalFilter === 'Yes',
      };
    }
  }

  onTableSort(event: any) {
    this.conceptRequestGrid.sortType = event.defaultSortOrder;
    this.conceptRequestGrid.sortKey = event.key;
    this.loadConceptRequest();
  }

  onPaginationChange(event: any) {
    this.conceptRequestGrid.pageIndex = event;
    this.conceptRequestGrid.offset = (event - 1) * 10;
    this.loadConceptRequest();
  }

  private openModal(
    modalName: string,
    modalTitle: string,
    modalWidth: string,
    footerButton?: ModalButtonConfig[]
  ): void {
    this.modalConfig = {
      modalName,
      isVisible: true,
      modalTitle,
      modalWidth,
      footerButton,
    };
  }

  ngOnDestroy(): void {
    this.listSubscription?.unsubscribe();
  }
}
