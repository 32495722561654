export enum ApiEndpoints {
  LAUNCH_BAR = 'launch-bar',
  USER_LOGIN = 'user_login',
  CONCEPT_KPI_DETAILS = 'concept_kpi_details',
  CONCEPT_LIST = 'concept_sets',
  CLONE_CONCEPT = 'clone_concept',
  CODE_DETAILS = 'view_concept_set',
  DATASET_DETAILS = 'datasets_onboarded',
  DOWNLOAD_CODES = 'download_codes',
  GET_SHARE_PUBLIC_APPROVER = 'get_approvers',
  SHARE_CONCEPT_PUBLICLY = 'share_concept_to_library',
  GET_SHARE_PRIVATE_APPROVER = 'get_users_for_sharing',
  SHARE_CONCEPT_PRIVATELY = 'share_concept_privately',
  UPDATE_EDIT_LOCK_STATUS = 'update_edit_lock',
  EDIT_CONCEPT = 'edit_concept_set_description',
  CONCEPT_REQUEST = 'approve_requests_list',
  CONCEPT_LOG = 'get_onboarding_log',
  APPROVE_REJECT_CONCEPT = 'approve_reject_publish_request',
  ONBOARDING_DETAILS = 'concept_onboarding_details',
  TRIGGER_ONBOARDING = 'trigger_onboarding',
  CODES_DATA = 'search_concept_codes',
  CREATE_CONCEPT = 'create_concept',
  DELETE_CONCEPT = 'delete_concept',
  VOCAB_DATA = 'view_vocabularies',
  DOMAIN_LIST = 'domain_list',
  DOWNLOAD_IMPORT_TEMPLATE = 'import_codes_template',
  READ_IMPORT_CODES = 'read_import_codes',
  SAVE_CONCEPT_CODES = 'save_concept_codes',
  SAVED_CONCEPT_CODES = 'concept_codes',
  AUDIT_TRAIL_DROPDOWN = 'list_concept_for_audit',
  AUDIT_TRAIL_GRID = 'view_audit_trail',
  ADMIN_ERROR_LOG = 'error-log',
  DELETE_ONBOARDED_CONCEPT = 'concept/delete_onboarded_concept',
  PROJECT_DETAILS = 'projects',
  GET_USERS_MAPPING = 'user_mapping',
}

export const localEndpointList: string[] = [
  // 'search_concept_codes',
  // 'view_audit_trail',
  // 'domain_list',
  // 'import_codes_template',
  // 'read_import_codes',
  // 'save_concept_codes',
  // 'concept_codes',
  // 'create_concept',
  // 'concept_sets',
  // 'approve_requests_list',
  // 'get_onboarding_log',
  // 'concept_onboarding_details',
  // 'trigger_concept_onboarding',
];
