import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@core/services/auth.interceptor';
import { RedirectionService } from '@core/services/redirection.service';
import { UserMappingService } from '@core/services/user_mapping.service';
import {
  RDAlertModule,
  RDButtonModule,
  RDHeaderModule,
  RDModalModule,
  RDSiderModule,
  RDSpinnerModule,
  RDTableModule,
  RDTabSetModule,
} from '@zs-ca/angular-cd-library';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InMemoryService } from './core/services/in-memory.service';
import { HeaderComponent } from './layout/header/header.component';
import { SiderComponent } from './layout/sider/sider.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SiderComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    OverlayModule,
    NzIconModule,
    NzNotificationModule,
    RDAlertModule,
    RDButtonModule,
    RDHeaderModule,
    RDModalModule,
    RDSiderModule,
    RDSpinnerModule,
    RDTableModule,
    RDTabSetModule,
    AdminModule,
    HttpClientInMemoryWebApiModule.forRoot(InMemoryService, {
      dataEncapsulation: false,
      passThruUnknownUrl: true,
    }),
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    InMemoryService,
    RedirectionService,
    UserMappingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
