import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpoints } from '@core/constants';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserMappingService {
  userMapping;
  constructor(private http: HttpClient) {
    this.userMapping = this.getProjectName();
  }

  // API Call to PAC Home to fetch user mapping
  private getProjectName() {
    const url =
      environment.config.pacHomeUrl + '/' + ApiEndpoints.GET_USERS_MAPPING;

    this.http.get(url).subscribe((data: any) => {
      this.userMapping = data['data'];
      console.log('User Mapping:', this.userMapping);
    });
  }

  getUserMapping() {
    return this.userMapping;
  }
}
